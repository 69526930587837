import React from "react"
import styled from "styled-components"
import "../../../css/typography.css"

const CardDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #0A2B59 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 16px #00000029;
    border-radius: 8px;
    opacity: 1;
    width: 170px;
    height: 170px;
    color: white;
    margin-bottom: 20px;

    >p {
        font-family: 'A Black';
        font-size: 18px;
        margin-bottom: 5px;
    }
    
    img {
        width: 80px;
        margin-bottom: 10px;
    }
    p {
        text-align: center;
    }
    @media screen and (min-width: 1200px){
        width: 180px;
        height: 180px;
        img {
            width: 90px;
        }
        >p {
            font-size: 18px;
        }
    }
    @media screen and (min-width: 1500px){
        width: 200px;
        height: 200px;
        img {
            width: 100px;
        }
        >p {
            font-size: 23px;
        }
    }
    @media screen and (min-width: 1800px){
        width: 250px;
        height: 250px;
        img {
            width: 130px;
        }
        >p {
            font-size: 28px;
        }
    }
    @media screen and (min-width: 2500px){
        width: 320px;
        height: 320px;
        img {
            width: 160px;
        }
        >p {
            font-size: 33px;
        }
    }
    @media screen and (min-width: 3500px){
        width: 450px;
        height: 450px;
        img {
            width: 230px;
        }
        >p {
            font-size: 50px;
        }
    }
    @media screen and (min-width: 4000px){
        width: 550px;
        height: 550px;
        img {
            width: 270px;
        }
        >p {
            font-size: 60px;
        }
    }
    @media screen and (max-width: 955px) {
        width: 200px;
    }
    @media screen and (max-width: 540px) {
        width: 180px;
        height: 180px;
    }
`

const Card = (props) => {

    return  (
        <CardDiv>
            <img src={props.image} alt={props.alt} />
            <p>{props.title}</p>
        </CardDiv>
    )
        
}

export default Card
