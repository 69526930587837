import React, {useState} from "react"
import styled from "styled-components"
import Down from "../../../images/components/Down.webp"
import "../../../css/typography.css"

const CardDiv = styled.div`
    background: #092247 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    color: white;
    padding: 0 5px 0 10px;
    width: 500px;
    margin-bottom: 10px;

    div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0;
        padding: 0% 5% 0% 5%;
    }
    
    div>p:nth-child(1) {
        font-family: 'A Heavy';
        font-size: 18px;
    }
    >p:nth-child(2) {
        font-family: 'A Regular';
        font-size: 18px;
        margin-top: 0;
        line-height: 20px;
    }
    img {
        width: 25px;
        height: 25px;
    }

    @media only screen and (min-width: 1200px){
        width: 550px;

        div>p:nth-child(1){
            font-size: 20px;
        }
        >p:nth-child(2){
            font-size: 18px;
        }
        img{}
    }
    @media only screen and (min-width: 1500px){
        width: 650px;

        div>p:nth-child(1){
            font-size: 25px;
        }
        >p:nth-child(2){
            font-size: 20px;
        }
        img{
            width: 30px;
            height: 30px;
        }
    }
    @media only screen and (min-width: 1800px){
        width: 800px;

        div>p:nth-child(1){
            font-size: 30px;
        }
        >p:nth-child(2){
            font-size: 25px;
            line-height: 30px;
        }
        img{
            width: 40px;
            height: 40px;
        }
    }
    @media only screen and (min-width: 2500px){
        width: 1000px;

        div>p:nth-child(1){
            font-size: 40px;
        }
        >p:nth-child(2){
            font-size: 33px;
            line-height: 40px;
        }
        img{
            width: 50px;
            height: 50px;
        }
    }
    @media only screen and (min-width: 3500px){
        width: 1400px;

        div>p:nth-child(1){
            font-size: 55px;
        }
        >p:nth-child(2){
            font-size: 45px;
            line-height: 55px;
        }
        img{
            width: 70px;
            height: 70px;
        }
    }
    @media only screen and (min-width: 4000px){
        width: 1700px;

        div>p:nth-child(1){
            font-size: 75px;
        }
        >p:nth-child(2){
            font-size: 60px;
            line-height: 65px;
        }
        img{
            width: 80px;
            height: 80px;
            margin-right: 30px;
        }
    }
    @media only screen and (max-width: 540px) {
        width: 80vw;
    }
`

const Card = (props) => {
    const [open, setOpen] = useState(false)

    return  (
        <CardDiv>
            <div>
                <p>{props.title}</p>
                <img src={open ? Down : Down} alt={props.alt} onClick={() => setOpen(!open)} />
            </div>
            {open ? (
            <p>{props.text}</p>
            ) : (
                null
            )}
        </CardDiv>
    )
        
}

export default Card
