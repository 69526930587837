import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import "../../../css/typography.css"

const CardDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 16px #00000029;
    border-radius: 8px;
    opacity: 1;
    width: 250px;

    >p:nth-child(2) {
        font-family: 'A Black';
        font-size: 18px;
        margin-bottom: 5px;
    }
    >p:nth-child(3) {
        font-family: 'A Medium';
        font-size: 18px;
        margin-top: 0;
    }
    img {
        width: 80px;
    }
    p {
        text-align: center;
    }
    @media screen and (min-width: 1200px) {
        width: 280px;
        height:250px;

        img {
            width: 90px;
        }
        >p:nth-child(2) {
            font-size: 25px;
        }
        >p:nth-child(3) {
            font-size: 20px;
        }
    }
    @media screen and (min-width: 1500px){
        width: 350px;
        height: 320px;
        img {
            width: 100px;
        }
        >p:nth-child(2) {
            font-size: 30px;
        }
        >p:nth-child(3) {
            font-size: 25px;
        }
    }
    @media screen and (min-width: 1800px){
        width: 400px;
        height: 370px;
        img {
            width: 150px;
        }
        >p:nth-child(2) {
            font-size: 35px;
        }
        >p:nth-child(3) {
            font-size: 30px;
        }
    }
    @media screen and (min-width: 2500px){
        width: 500px;
        height: 450px;
        img {
            width: 150px;
        }
        >p:nth-child(2) {
            font-size: 45px;
        }
        >p:nth-child(3) {
            font-size: 40px;
        }
    }
    @media screen and (min-width: 3500px){
        width: 800px;
        height: 700px;
        img {
            width: 250px;
        }
        >p:nth-child(2) {
            font-size: 60px;
        }
        >p:nth-child(3) {
            font-size: 55px;
        }
    }
    @media screen and (min-width: 4000px){
        width: 950px;
        height: 800px;
        img {
            width: 350px;
        }
        >p:nth-child(2) {
            font-size: 70px;
        }
        >p:nth-child(3) {
            font-size: 65px;
        }
    }
    @media screen and (max-width: 955px) {
        width: 200px;
    }
    @media only screen and (max-width: 765px) {
        width: 70vw;
        margin-bottom: 50px;
    }
`

const Card = (props) => {

    return  (
        <CardDiv>
            <img src={props.image} alt={props.alt} />
            <p>{props.title}</p>
            <p>{props.text}</p>
        </CardDiv>
    )
        
}

export default Card
