import React, {useState, useEffect} from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import Background from "../images/home/Background.webp"
import BackgroundMov from "../images/home/BackgroundMov.webp"
import Laptop from "../images/home/Laptop.webp"
import CTA from "../components/buttons/cta"
import CTABlue from "../components/buttons/ctaBlue"
import CTAWhite from "../components/buttons/ctaWhite"
import FirstCard from "../components/home/firstCard"
import Check from "../images/home/Check.webp"
import Click from "../images/home/Click.webp"
import Idea from "../images/home/Idea.webp"
import ThirdBack from "../images/home/ThirdBack.webp"
import LastSecImage from "../images/home/LastSecImage.webp"
import ThirdBackMov from "../images/home/ThirdBackMov.webp"
import Computer from "../images/home/Computer.webp"
import useWindowSize from "../hooks/useWindowSize"
import SecondCard from "../components/home/secondcard"
import Cheklist from "../images/home/secondCardIcons/Checklist.webp"
import Graficos from "../images/home/secondCardIcons/Gráficos.webp"
import Reportes from "../images/home/secondCardIcons/Reportes.webp"
import Sucursales from "../images/home/secondCardIcons/Sucursales.webp"
import Tipos from "../images/home/secondCardIcons/Tipos.webp"
import Dropdown from "../components/home/dropdown"
import "../css/typography.css"

const FirstSection =styled.div`
  color: white;
  background-image: url(${Background});
  background-size: cover;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5%;
  padding-bottom: 3%;

  h1 {
    font-family: 'A Black';
    font-size: 32px;
    margin-bottom: 0;
  }
  p {
    font-family: 'A Regular';
    font-size: 20px;
    margin-bottom: 0;
    line-height: 25px;
  }
  img {
    width: 700px;
    margin-top: 30px;
  }
  >div:nth-child(1){
    display: flex;
    flex-direction: column;
  }
  >div:nth-child(1)>div{
    display: flex;
    margin-top: 5%;
    justify-content: space-between;
    width: 400px;
  }

  @media only screen and (min-width: 1200px) {  
    padding-bottom: 5%;

    h1 {
      font-size: 45px;
    }
    p {
      width: 460px;
      font-size: 23px;
      margin-bottom: 15px;
    }
  }
  @media only screen and (min-width: 1500px) {  
    padding-bottom: 5%;

    h1 {
      font-size: 50px;
    }
    p {
      width: 760px;
      font-size: 25px;
    }
    >div:nth-child(1){
      width:700px;
    }
  }
  @media only screen and (min-width: 1800px) {  
    h1 {
      font-size: 60px;
      margin-top: 80px;
    }
    p {
      width: 760px;
      font-size: 30px;
    }
    img {
      width: 900px;
    } 
    >div:nth-child(1)>div{
      width:470px;
    }
  }
  @media only screen and (min-width: 2500px) {  
    h1 {
      font-size: 70px;
      margin-top: 80px;
    }
    p {
      width: 1000px;
      font-size: 45px;
      line-height: 50px;
    }
    img {
      width: 1200px;
    } 
    >div:nth-child(1){
      width: 1000px;
    }
    >div:nth-child(1)>div{
      width:620px;
    }
  }
  @media only screen and (min-width: 3500px) {  
    h1 {
      font-size: 100px;
      margin-top: 80px;
    }
    p {
      width: 1300px;
      font-size: 55px;
      line-height: 60px;
    }
    img {
      width: 1500px;
    } 
    >div:nth-child(1){
      width: 1500px;
    }
    >div:nth-child(1)>div{
      width:820px;
    }
  }
  @media only screen and (min-width: 4000px) {  
    h1 {
      font-size: 130px;
      margin-top: 80px;
    }
    p {
      width: 1700px;
      font-size: 70px;
      line-height: 70px;
    }
    img {
      width: 2000px;
    } 
    >div:nth-child(1){
      width: 2000px;
    }
    >div:nth-child(1)>div{
      width: 1050px;
    }
  }

  @media only screen and (max-width: 1115px) {  
    padding-bottom: 5%;

    img {
      width: 600px;
    }
  }
  @media only screen and (max-width: 1015px) {  
    padding-bottom: 15%;

    img {
      width: 400px;
      margin-top: 20px;
    }
  }
  @media only screen and (max-width: 820px) {  
    flex-direction: column;
    padding: 5%;

    img {
      width: 500px;
    }
    >div:nth-child(1){
      align-items: center;
    }
    h1 {
      align-self: flex-start;
    }
  }
  @media only screen and (max-width: 540px) {  
    background-image: url(${BackgroundMov});
    padding: 10%;

    h1 {
      font-size: 7vw;
    }
    p {
      font-size: 5.5vw;
      line-height: 30px;
    }
    img {
      width: 130vw;
      margin-left: -16vw;
    }
    >div:nth-child(1)>div{
      width: 90vw;
    }
  }
`

const SecondSection = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 5%;
  
  >div {
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin-top: -80px;
  }
  @media only screen and (min-width: 1500px) {
    >div {
      width: 1300px;
    }
  }
  @media only screen and (min-width: 1800px) {
    >div {
      width: 1500px;
    }
  }
  @media only screen and (min-width: 2500px) {
    >div {
      width: 2000px;
    }
  }
  @media only screen and (min-width: 3500px) {
    >div {
      width: 3000px;
    }
  }
  @media only screen and (min-width: 4000px) {
    >div {
      width: 3500px;
    }
  }
  @media only screen and (max-width: 1250px) {
    >div {
      width: 90%;
    }
  }
  @media only screen and (max-width: 765px) {
    padding-top: 15%;

    >div {
      flex-direction: column;
      align-items: center;
      margin-top: 0;
    }
  }
`

const ThirdSection = styled.div`
  color: white;
  position: relative;

  img {
    width: 1300px;
  }
  h2 {
    font-family: 'A Black';
    font-size: 28px;
    margin-bottom: 10px;
  }
  p {
    font-family: 'A Regular';
    font-size: 20px;
    width: 40%;
    margin-top: 0;
    margin-bottom: 30px;
  }
  >div {
    position: absolute;
    top: 15%;
    left: 5%;
  }
  @media only screen and (min-width: 1200px) {
    img {
      width: 1200px;
    }
    h2 {
      font-size: 35px;
    }
    p {
      font-size: 23px;
      width: 55%;
      margin-bottom: 20px;
    }
  }
  @media only screen and (min-width: 1500px) {
    img {
      width: 1500px;
    }
    h2 {
      font-size: 45px;
    }
    p {
      font-size: 30px;
      width: 55%;
      margin-bottom: 20px;
    }
  }
  @media only screen and (min-width: 1800px) {
    img {
      width: 1800px;
    }
    h2 {
      font-size: 55px;
    }
    p {
      font-size: 35px;
      width: 55%;
      margin-bottom: 30px;
    }
  }
  @media only screen and (min-width: 2500px) {
    img {
      width: 2500px;
    }
    h2 {
      font-size: 65px;
    }
    p {
      font-size: 50px;
      width: 55%;
      margin-bottom: 60px;
    }
  }
  @media only screen and (min-width: 3500px) {
    img {
      width: 3500px;
    }
    h2 {
      font-size: 95px;
      margin-bottom: 30px;
    }
    >div {
      height:800px;
    }
    p {
      font-size: 60px;
      width: 1850px;
      margin-bottom: 90px;
    }
  }
  @media only screen and (min-width: 4000px) {
    img {
      width: 4000px;
    }
    h2 {
      font-size: 125px;
    }
    p {
      font-size: 70px;
      width: 2000px;
    }
  }
  @media only screen and (max-width: 1190px) {
    img {
      width: 95%;
    }
    p {
      width: 55%;
    }
  }
  @media only screen and (max-width: 1040px) {
    >div {
      top: 8%;
    }
  }
  @media only screen and (max-width: 905px) {
    >div>div{
      display: none;
    }
  }
`

const ThirdSectionMov = styled.div`
  background-image: url(${ThirdBackMov});
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10%;
  color: white;
  margin-bottom: 200px;

  h2 {
    font-family: 'A Black';
    margin-bottom: 10px;
    font-size: 20px;
  }
  p {
    font-family: 'A Regular';
    margin-top: 0;
    font-size: 18px;
    margin-bottom: 30px;
  }

  img {
    position: absolute;
    width: 70%;
    top: 90%;
    left: 14%;
  }

  @media only screen and (max-width: 320px) { 
    img, div {
      display: none;
    }
  }
  @media only screen and (max-width: 765px) {
    margin-bottom: 300px;
    img {
      top: 85%;
    }
  }
  @media only screen and (max-width: 540px) {
    margin-bottom: 200px;
    img {
      top: 90%
    }
  }
`

const FourthSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5% 8% 0 8%;
  margin-bottom: 5%;

  >div:nth-child(1) {
    display: flex;
    flex-direction: column;
    width: 500px;
    margin-right: 10px;
  }
  >div:nth-child(2) {
    display: flex;
    justify-content: space-between;
    width: 600px;
    flex-wrap: wrap;
  }
  h2 {
    font-family: 'A Black';
    margin-bottom: 10px;
    font-size: 25px;
    color: #092247;
  }
  >div>p {
    font-family: 'A Regular';
    margin-top: 0;
    font-size: 20px;
    margin-bottom: 30px;
    color: #092247;
  }

  >div:nth-child(3) {
    margin-top: 5%;
  }

  @media only screen and (min-width: 1200px){
    h2 {
      font-size:35px;
    }
    >div>p{
      font-size: 20px;
    }
    >div:nth-child(2){
      width: 600px;
    }
  }
  @media only screen and (min-width: 1500px){
    h2 {
      font-size:45px;
    }
    >div>p{
      font-size: 25px;
    }
    >div:nth-child(1){
      width: 700px;
    }
    >div:nth-child(2){
      width: 700px;
    }
  }
  @media only screen and (min-width: 1800px){
    h2 {
      font-size:55px;
    }
    >div>p{
      font-size: 35px;
    }
    >div:nth-child(1){
      width: 900px;
    }
    >div:nth-child(2){
      width: 900px;
    }
  }
  @media only screen and (min-width: 2500px){
    h2 {
      font-size:65px;
    }
    >div>p{
      font-size: 45px;
    }
    >div:nth-child(1){
      width: 1100px;
    }
    >div:nth-child(2){
      width: 1000px;
    }
  }
  @media only screen and (min-width: 3500px){
    h2 {
      font-size: 100px;
    }
    >div>p{
      font-size: 60px;
    }
    >div:nth-child(1){
      width: 1700px;
    }
    >div:nth-child(2){
      width: 1700px;
    }
  }
  @media only screen and (min-width: 4000px){
    h2 {
      font-size: 130px;
    }
    >div>p{
      font-size: 70px;
    }
    >div:nth-child(1){
      width: 2000px;
    }
    >div:nth-child(2){
      width: 2000px;
    }
  }
  @media only screen and (max-width: 886px) {
    flex-direction: column;
    padding: 5%;
    align-items: center;

    >div:nth-child(1) {
      width: 100%;
    }
    >div:nth-child(2) {
      width: 100%;
    }
  }
  @media only screen and (max-width: 399px) {
    >div:nth-child(2) {
      justify-content: center;
    }
  }
`

const LastSection = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #e6fbff;
  padding: 5% 0 5% 5%;

  >div {
    display: flex;
    flex-direction: column;
  }
  h2 {
    font-family: 'A Black';
    margin-bottom: 10px;
    font-size: 25px;
    color: #092247;
  }
  >img {
    width: 35%;
    height: 100%;
  }
  @media only screen and (min-width: 1200px) {
    h2 {
      font-size: 35px;
    }
    >img {
      width: 450px;
    }
  }
  @media only screen and (min-width: 1500px) {
    h2 {
      font-size: 45px;
    }
    >img {
      width: 550px;
    }
  }
  @media only screen and (min-width: 1800px) {
    h2 {
      font-size: 55px;
    }
    >img {
      width: 700px;
    }
  }
  @media only screen and (min-width: 2500px) {
    h2 {
      font-size: 65px;
    }
    >img {
      width: 1000px;
    }
  }
  @media only screen and (min-width: 3500px) {
    h2 {
      font-size: 95px;
    }
    >img {
      width: 1300px;
    }
  }
  @media only screen and (min-width: 4000px) {
    h2 {
      font-size: 125px;
    }
    >img {
      width: 1700px;
    }
  }
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    padding: 5%;

    >div {
      align-items: center;
    }
    >img {
      width: 70vw;
      margin-top: 5%;
      align-self: flex-end;
      margin-right: -100px;
    }
  }
  @media only screen and (max-width: 540px) {
    >img {
      width: 90vw;
    }
  }
`

const IndexPage = () => {
  const [hasMounted, setHasMounted] = useState(false) 
  const windowSize = useWindowSize()

    useEffect(() => {
        setHasMounted(true)
    }, [])

return hasMounted ? (        
    <Layout>
      <FirstSection>
        <div>
          <h1>CONOCE VENTUM</h1>
          <p>Ventum surgió como una idea para optimizar los procesos de supervisión en negocios propios.</p>
          <p>Tal fue su éxito que se convirtió en una herramienta que ahora se implementa en grandes organizaciones.</p>
          <div>
            <CTA text='Conoce más' link='/nuestro-sistema' />
            <CTAWhite text='Contáctanos' link='/contacto' />
          </div>
        </div>
        <div>
          <img src={Laptop} alt="Laptop" />
        </div>
      </FirstSection>
      <SecondSection>
        <div>
          <FirstCard image={Click} title='Fácil de utilizar' text='Contamos con un sistema que funciona de manera sencilla e intuitiva' alt='Click' />
          <FirstCard image={Check} title='Sistema 100% seguro' text='Utilizamos tecnología y softwares con alta seguridad' alt='Check' />
          <FirstCard image={Idea} title='Soluciones exitosas' text='Organiza y optimiza la operación de tu negocio' alt='Idea' />
        </div>
      </SecondSection>
      {windowSize > 765 ? (
        <ThirdSection>
          <img src={ThirdBack} alt="Third background" />
          <div>
            <h2>¿Cómo funciona?</h2>
            <p>Ventum funciona de forma sencilla e intuitiva. En menos de tres clics puedes acceder a toda la información importante sobre tu empresa.</p>
            <CTABlue text='Conoce más' link='/nuestro-sistema' />
          </div>
        </ThirdSection>
      ) : (
        <ThirdSectionMov>
          <h2>¿Cómo funciona?</h2>
          <p>Ventum funciona de forma sencilla e intuitiva. En menos de tres clics puedes acceder a toda la información importante sobre tu empresa.</p>
          <CTABlue text='Conoce más' link='/nuestro-sistema' />
          <img src={Computer} alt="Computer" />
        </ThirdSectionMov>
      )}
      <FourthSection>
        <div>
          <h2>Características del sistema</h2>
          <p>El sistema Ventum cuenta con diferentes características diseñadas para mejorar la estructura, calidad y el funcionamiento de tu empresa.</p>
          {windowSize > 886 ? (
          <CTA text='Conoce más' link='/nuestro-sistema' />
          ) : (
            null
          )}
        </div>
        <div>
          <SecondCard image={Cheklist} title='Visitas' />
          <SecondCard image={Cheklist} title='Checklist' />
          <SecondCard image={Sucursales} title='Sucursales' />
          <SecondCard image={Reportes} title='Reportes' />
          <SecondCard image={Tipos} title='Tipos de reportes' />
          <SecondCard image={Graficos} title='Gráficos' />
        </div>
        {windowSize < 886 ? (
          <div>
            <CTA text='Conoce más' link='/nuestro-sistema' />
          </div>
          ) : (
            null
          )}
      </FourthSection>
      <LastSection>
        <div>
          <h2>Nuestros servicios</h2>
          <Dropdown title='Supervisión de sucursales' text='Con Ventum podemos realizar seguimiento a los pendientes en sucursal, levantados por los distintos Checklist que nos ayudaran a saber si la normativa de la sucursal se encuentra en estándar o no.' />
          <Dropdown title='Reportes, estadísticas y Big Data' text='Contamos con una gran variedad de reportes que nos permite analizar el estado de las sucursales, paras así poder tomar las decisiones correctas en el momento adecuado.' />
          <Dropdown title='Recursos humanos' text='Podremos verificar en el sistema la ubicación de cada colaborador en la sucursal que está trabajando, así como también una base de datos que puede ayudar a tener todo mejor organizado y a la mano.' />
          <Dropdown title='Gestión de To-Do´s' text='Una de las funciones más interesante es la gestión de To-Do´s, donde podremos tener el histórico de cada tarea documentada con o sin imágenes. Todo esto sirve como estadístico para dar seguimiento a que tareas se levantan mas y cuanto en promedio se tardan en solucionar.' />
          <Dropdown title='Gestión de usuarios' text='Con Ventum podemos gestionar Usuarios ilimitados, es decir podremos levantar los usuarios que necesitemos si pagar un costo extra. (Todo esto es gestionable en el área de sistemas)' />
          <Dropdown title='Integraciones' text='Ventum ofrece una fácil integración con sus herramientas. Contamos con un equipo técnico que da soporte a cualquier transición que se necesite hacer para automatizar la carga de información en la plataforma.' />
        </div>
        <img src={LastSecImage} alt="Image" />
      </LastSection>
    </Layout>
  ) : (
    null
  )
}

export default IndexPage
